import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22styles%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61W227bMAx9z1cQMAqkgO2JkizL2su2dB%2FiJmqStbnM6bp0Q%2F99kJw2lmRbabEk0MPJOSQPZZhUzW73CH8nAFl2f3jGZkUUNMvbKSUyhdfj%2BnOHgJZQT7GSKZwPkpcOjbZxHJZDYKc4FE2StwMdEh9K5sYqTjSSwunnVSNCQuEQSgVJIb6JGe2iUkFCNNFIumilYLvb6i5UK0hoSSV15LcKkkrOWFl10bmCRBfm20UXCpKZKOXNTRfVCpLvRMxKh3unYAL2Q3K2Pxoz9iz3R1gdHqZkoZdArqAgV%2FDJcPh1ehKgpdK82h%2BBWUFGch7XUcstLFXkstVdkI%2FbfNLmQ8ypEWKOcWFpScitQZSt7oJCT0kotUIqbZgMbckxqfHDmK2Vt4Yzekmt1HgsiGlN29mMWtsDMqvq3ufy4%2FfZXh6x9k5O6QVOTWvsc1C8v9rVe6sVnWptnbk45c%2FI8L2IzrNn6uX2LF4foQuymbLQ3ApSm3HMpwh9rhUkN1h9FaKL%2FlBActroTRe8NyD3wAcDSg%2FcKMBAvjWg8MCdAhrE3CtggfynAp4TD2wM6Gc%2FKBBBzEcFMpD%2FUlAFJT0pQBFQf%2FdZOhrQz%2FTc5%2FOPAf1CkRj3fibEvp4gNWgQgfW1CrlB%2FRKwMM0KuKKvhVgq8xr0UWk6G9RbGTSIUCsowxpuzTUEcefmHtrKXiZfNnqxruEwb7TeQr1dwHTf6DvdHLL57mHXZIf5Sm%2B0gkXd3F%2Fbsa7eBnww4lmVAicpMG4nYTjhaZUCIylQfh6owYA%2Fk7z%2FWU8U9Di8N5P0WK%2FTnRZFCucjLEkMEQuP6E57d94viPm6eKVgvX3SzePUd1APKNzR7w5%2FppnmHt8d%2F0MLQN8KMPwiLu1bDvkVFMEEiO4CMfHoQhATj28FMfXoahA1HdkPovrRJSHatZFNoU%2F7JnVvf%2Fkfbn9kc4j2YGh9eIeF1YcsCM%2FC4DoRk47uFDHxyGLRJx3ogLtfvExe%2FgFKl9FcjQ0AAA%3D%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22styles%2Fanimation.css.ts.vanilla.css%22%2C%22source%22%3A%22QGtleWZyYW1lcyBfMXdvbWJwYzAgewogIDAlIHsKICAgIHRyYW5zZm9ybTogcm90YXRlKDBkZWcpOwogIH0KICAxMDAlIHsKICAgIHRyYW5zZm9ybTogcm90YXRlKDM1OWRlZyk7CiAgfQp9Ci5fMXdvbWJwYzEgewogIGFuaW1hdGlvbjogMXMgaW5maW5pdGUgXzF3b21icGMwIGxpbmVhcjsKICB0cmFuc2Zvcm0tb3JpZ2luOiBjZW50ZXI7Cn0%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22styles%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xMDBiOW5jMCB7CiAgY29sb3I6IHZhcigtLWtzeTFyaGEpOwogIHRleHQtZGVjb3JhdGlvbjogbm9uZTsKfQouXzEwMGI5bmMwOmhvdmVyIHsKICB0ZXh0LWRlY29yYXRpb246IHVuZGVybGluZTsKfQouXzEwMGI5bmMxIHsKICBjb2xvcjogdmFyKC0ta3N5MXJoYSk7CiAgdGV4dC1kZWNvcmF0aW9uOiBub25lOwp9Ci5fMTAwYjluYzIgewogIGNvbG9yOiB2YXIoLS1rc3kxcmhhKTsKICB0ZXh0LWRlY29yYXRpb246IHVuZGVybGluZTsKfQouXzEwMGI5bmMzIHsKICBjb2xvcjogdmFyKC0ta3N5MXJoYSk7CiAgZm9udC1zaXplOiB2YXIoLS1rc3kxcmh5KTsKICBsaW5lLWhlaWdodDogMS41OwogIG1hcmdpbjogLTAuMzc2NWVtIDAgLTAuNDE1NWVtOwogIHdvcmQtd3JhcDogYnJlYWstd29yZDsKfQ%3D%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22styles%2Flayout.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xbTJyZzJkMCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGdhcDogdmFyKC0ta3N5MXJocCk7CiAgcGFkZGluZy1sZWZ0OiB2YXIoLS1rc3kxcmhvKTsKfQ%3D%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fatoms%2FTypography%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA72WzY6jMAzH730KX1azPQTxUSibPswqEANZ0gSFtKWzmndfkVZtabt0RqA5oRhj%2F3%2BOceL9Xvk61Dsf%2Fi4Aci21obBn5ichdXsMTMWWmwXAlplSKAo%2BsJ3VJ0tHDoLbisIqNbjtbed14Ps%2F3FIbTjKDrKbgHqS3bBYfC%2B%2BcNXBZM204GiKxsBTCpoNWS8EHKpKBijENDeNcqHIIoZbg3xuGywf5V5GhE8lF20h2pJBJnde9u96jKaQ%2BUKgE56h6m8XOkusLlFI0rWhd9EpYJG3DcqSg9MGw5jZL5LKQA2a1sCTTHdFGoLIU9misyJnsg2Qsr0ujd4r3MRRuLuWj4G%2FGdvCi%2FybF%2Fyheb9zKqS20sqQV7zhMGIQuoxQKSYWirCyFKPKSphuEoDTDQhs8d56yjvbt7brTJNPW6i0F4nthEsenHb6QWJZJvAvJCotmJKLVzSlcmLwMF49CBo%2BQYeqlQ8h4fsh4XshkFNJ%2FArkaIibzIybzIq7HEN%2BfEK6HhOuvEa6C14TrzxNG69eE6Rjh4ZEwuGvTdH7CdF7CX%2BPn0xfQveju6DjN0WsqNlbM4%2Bsfgs1fTDZvMQMYsj4pa7y8%2FSCbPu7z6cOUTx1VOHEQFBN%2Fs%2FL7mria2MTXFqk%2B1yLCubn7D5OiVBRyVBbNrc%2BfB5%2F%2BznfrUT94mF7fZvHxDxYvVw6pCgAA%22%7D"
export var blockquote = '_40o2ou1';
export var ellipsisContainer = '_40o2ou2';
export var lineClampContainer = '_40o2ou3';
export var sizeVariant = {headingLarge:'_40o2ou4',headingMedium:'_40o2ou5',headingSmall:'_40o2ou6',headingSmaller:'_40o2ou7',legal:'_40o2ou8',monospace:'_40o2ou9',paragraph:'_40o2oua'};
export var sizeVariantNoCapsize = {headingLarge:'_40o2oub',headingMedium:'_40o2ouc',headingSmall:'_40o2oud',headingSmaller:'_40o2oue',legal:'_40o2ouf',monospace:'_40o2oug',paragraph:'_40o2ouh'};
export var textAlignVariant = {center:'_40o2oui',left:'_40o2ouj',right:'_40o2ouk'};
export var typography = '_40o2ou0';